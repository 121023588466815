<!-- 勤工俭学 -->
<template>
  <div class="study">
    <img style="width: 100%" src="../../assets/img/zxz1vbax.png" alt="" />
    <div
      class="cons1 flexs"
      v-for="(item, index) in datalist"
      :key="index"
      @click="todetail(item.id)"
    >
      <div class="cons2 flexs1">
        <div class="tleft ssaa1">{{ item.title }}</div>
        <div class="tleft ddd134">{{ item.created_at.split(" ")[0] }}</div>
      </div>
      <div class="cos23">
        <img style="width: 6em; height: 6em" :src="item.thumb" alt="" />
      </div>
    </div>

    <page-footer />
  </div>
</template>

<script>
export default {
  components: {
    "page-footer": () => import("../../components/footer.vue"),
  },
  data() {
    return {
      datalist: [],
    };
  },
  created() {
    console.log(889);
    this.getNewsList();
  },
  methods: {
    getNewsList() {
      var self = this;
      this.axios({
        method: "post",
        url: "https://www.zhixiaozi.com/square/api/v1/article/list",
        data: {},
      }).then(function (resp) {
        self.datalist = resp.data.data.lists;
      });
    },
    todetail(id) {
      this.$router.push({
        path: `/detail?id=${id}`,
      });
    },
  },
};
</script>

<style scoped>
.study {
  width: 100%;
}
.ddd134 {
  font-size: 0.8em;
  color: #bfc9cc;
}
.cons1 {
  width: 90%;
  margin: 0 auto;
  padding: 10px 0px;
  border-bottom: 1px dotted rgb(238, 235, 235);
}
.ssaa1 {
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.tleft {
  text-align: left;
}
.flexs {
  display: flex;
  justify-content: space-around;
}
.flexs1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.cos23 {
  margin-left: 2%;
}
.cons2 {
  width: 100%;
}
</style>
